import React from 'react'
import CTACard from '../../CTACard/CTACard'
import RecomendacionCard from '../../../../../sites/rodi/components/RecomendacionCard/RecomendacionCard'
import Title from '../../Title'
import styles from './index.module.scss'
import { ImageData } from '../../../utils/imagesS3'
import ResponsiveSliderBanner from '../../ResponsiveSliderBanner/ResponsiveSliderBanner'
import { t } from '../../../i18n'
import LogosMotoList from './LogoList/LogosMotoList'
import SelectorMotoComponent from '../../FunnelSelectorMoto/SelectorMotoComponent'

interface Props {
  title: string
  children: JSX.Element | JSX.Element[]
  textRecomendation?: string
  imageDesktop?: ImageData[]
  imageMobile?: ImageData[]
  descriptionCTA?: string
  linkButtonCTA?: string
  textButtonCTA?: string
  ctaLink?: any
}
export default function LayoutLanding({
  title,
  children,
  textRecomendation,
  imageDesktop,
  imageMobile,
  descriptionCTA,
  linkButtonCTA,
  textButtonCTA,
  ctaLink,
}: Props) {
  return (
    <div className={styles.layoutlanding_container}>
      <Title text={title} />
      <ResponsiveSliderBanner
        sizesVariant="banner_neumaticos"
        desktopImages={imageDesktop}
        mobileImages={imageMobile}
      />
      <div className={styles.selector_container}>
        <div className={styles.fichaSliderContent}>
          <SelectorMotoComponent route="landing-moto" />
        </div>
      </div>
      <section className={styles.sectionMarcasMoto}>
        <h2>{t('layout_moto_marcas')}</h2>
        <LogosMotoList />
      </section>
      <div className={styles.layoutlanding_inferior}>
        <div className={styles.layoutlanding_left}>{children}</div>
        <div className={styles.layoutlanding_right}>
          {textRecomendation && (
            <RecomendacionCard recomendacion={textRecomendation} />
          )}
          {descriptionCTA && (
            <CTACard
              description={descriptionCTA}
              linkButton={linkButtonCTA}
              textButton={textButtonCTA}
              ctaLink={ctaLink}
            />
          )}
        </div>
      </div>
    </div>
  )
}
