import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import LayoutLanding from '../../../../src/common/components/layouts/layoutLandingMoto/LayoutLandingMoto'
import { SeoData } from '../../../../src/common/types/Seo'
import { locale, t } from '../../../../src/common/i18n'
import LandingMoto from '../../static/landing-moto'
import { getImages } from '../../../../src/common/utils/imagesS3'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/pages/landing.module.scss'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../constants/menu'

const bannerPlaceholder = {
  desktop: {
    es: {
      Key: '',
      url: 'https://media.rodi.es/landings/tipo-vehiculo/moto/banners/es/desktop/neumaticos-moto.jpg',
      link: '',
      alt: '',
    },
    ca: {
      Key: '',
      url: 'https://media.rodi.es/landings/tipo-vehiculo/moto/banners/ca/desktop/pneumatics-moto.jpg',
      link: '',
      alt: '',
    },
  },
  mobile: {
    es: {
      Key: '',
      url: 'https://media.rodi.es/landings/tipo-vehiculo/moto/banners/es/mobile/neumaticos-moto-mobile.jpg',
      link: '',
      alt: '',
    },
    ca: {
      Key: '',
      url: 'https://media.rodi.es/landings/tipo-vehiculo/moto/banners/ca/mobile/pneumatics-moto-mobile.jpg',
      link: '',
      alt: '',
    },
  },
}
interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    bannerPlaceholder.mobile[locale]
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    bannerPlaceholder.desktop[locale]
  )
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.moto'),
        },
      ]}>
      <LayoutLanding
        title={t('layout_moto_title')}
        imageDesktop={banners_desktop}
        imageMobile={banners_mobile}
        descriptionCTA={t('CTA.moto')}
        textButtonCTA={t('ficha-taller.appointment')}
        ctaLink={{
          cta: 'cita_previa',
          cta_es: 'Solicitar cita previa',
          cta_ca: 'Demanar cita prèvia',
        }}
        textRecomendation={
          t('recomendaciones.landing_moto') === 'undefined'
            ? undefined
            : t('recomendaciones.landing_moto')
        }>
        <LandingMoto styles={styles} data={data} />
      </LayoutLanding>
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingMotoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    imagenes_ca_desktop: allS3Asset(
      filter: {
        Key: {
          glob: "landings/tipo-vehiculo/moto/imagenes/ca/desktop/header/*"
        }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_desktop: allS3Asset(
      filter: {
        Key: {
          glob: "landings/tipo-vehiculo/moto/imagenes/es/desktop/header/*"
        }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/ca/mobile/header/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/es/mobile/header/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: "landings/moto/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: "landings/moto/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: "landings/moto/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: "landings/moto/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image1_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/es/desktop/body1/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image1_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/es/mobile/body1/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image2_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/es/desktop/body2/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image2_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/es/mobile/body2/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image1_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/ca/desktop/body1/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image1_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/ca/mobile/body1/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image2_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/ca/desktop/body2/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    image2_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/moto/imagenes/ca/mobile/body2/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
