import styles from './logoList.module.scss'
import Bridgestone from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../../../src/common/assets/images/logo_marcas/bridgestone.svg'
import Continental from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/continental.svg'
import Dunlop from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/dunlop.svg'
import Michelin from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/michelin.svg'
import Pirelli from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/pirelli.svg'
import Metzeler from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/metzeler.svg'
import Mitas from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/mitas.svg'
import XGrip from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/x-grip.svg'
import Cst from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/cst.svg'
import DeliTire from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/deli-tire.svg'
import Maxxis from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/maxxis.svg'
import Taifa from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../assets/images/logo_marcas/taifa.svg'

type LogosMoto = {
  marca: string
  src: string
  className: string
  routeId: string
  sites: string[]
}

const logosMoto: LogosMoto[] = [
  {
    marca: 'michelin',
    src: Michelin,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'pirelli',
    src: Pirelli,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'dunlop',
    src: Dunlop,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'continental',
    src: Continental,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'bridgestone',
    src: Bridgestone,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'metzeler',
    src: Metzeler,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'mitas',
    src: Mitas,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'x-grip',
    src: XGrip,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'cst',
    src: Cst,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'delitire',
    src: DeliTire,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'maxxis',
    src: Maxxis,
    className: styles.logo,
    routeId: 'neumaticos.marca-moto',
    sites: ['rodi', 'elpaso'],
  },
  {
    marca: 'taifa',
    src: Taifa,
    className: styles.logo,
    routeId: 'neumaticos.marca',
    sites: ['rodi'],
  },
]

export default logosMoto
