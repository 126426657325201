import { Link } from 'gatsby'
import React from 'react'
import styles from './logoList.module.scss'
import logos from './LogosMoto'
import route from '../../../../utils/route'
import { t } from '../../../../../../sites/rodi/i18n'

const altImagen = (marca: string) =>
  `${t('layout_moto_marcas_logo_alt', { marca }).toLowerCase()}`

const LogosMotoList: React.FC = () => (
  <div className={styles.logoList_big_container}>
    <div className={styles.logoList_container}>
      {logos.map(
        (logo) =>
          logo.sites.includes(process.env.GATSBY_WEB) && (
            <Link
              key={logo.marca}
              to={route(logo.routeId, { marca: logo.marca })}>
              <img
                src={logo.src}
                alt={altImagen(logo.marca)}
                loading="lazy"
                width="100%"
                height="48"
                className={logo.className}
              />
            </Link>
          )
      )}
    </div>
  </div>
)

export default LogosMotoList
