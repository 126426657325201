import styles from '../SelectorMotoComponent.module.scss'
import SelectorContainerMoto from '../Common/SelectorContainerMoto'
import route from '../../../../../src/common/utils/route'
import SelectorMotoDesktop from './SelectorMotoDesktop'
import SelectorModeloMotoDesktop from '../Modelo/SelectorModeloMotoDesktop'
import React, { useContext, useEffect, useRef, useState } from 'react'
import NeumaticoHomeMoto from '../../../../../src/common/assets/home/neumatico_home_moto.svg'
import MotoIcon from '../../../../../src/common/assets/images/family_icons/icon_moto.svg'
import dataLayer from '../../../../../src/common/helpers/functions/dataLayer'
import { ModalErrorMedidas } from '../Common/modalErrorMedidas/modalErrorMedidas'
import { t } from '../../../i18n'
import AppContext from '../../../../../src/common/context/context'
import { resetMarca } from '../../../context/actions/selectorMotoActions'

interface Props {
  onSumbitModeloMoto: () => void
  onSumbitMedidas?: () => void
  routeIndex: string
}

const SelectorMotoComponentTabsDesktop = ({
  onSumbitModeloMoto,
  onSumbitMedidas,
  routeIndex,
}: Props) => {
  const { dispatch } = useContext(AppContext)
  const [tabActive, setTabActive] = useState(0)
  const isInitialMount = useRef(true)
  const tabsArray = [
    {
      id: 0,
      icon: <NeumaticoHomeMoto className={styles.icon_neumatico} />,
      title: 'selector-moto.buscar_por_medida',
    },
    {
      id: 1,
      icon: <MotoIcon className={styles.icon_moto} />,
      title: 'selector-moto.buscar_por_moto',
    },
  ]

  useEffect(() => {
    // No informamos en el primer render, puesto que no es que el usuario haya cambiado
    if (isInitialMount.current) {
      isInitialMount.current = false
      dispatch(resetMarca())
      return
    }
    dataLayer.push({
      event: 'tab',
      action: 'click',
      label: tabActive === 0 ? 'neumaticos' : 'moto',
    })
  }, [tabActive])

  return (
    <div>
      {
        <div className={styles.tabs_home_wrapper}>
          <ul className={styles.tabrow}>
            {tabsArray.map((tab) => {
              return (
                <li
                  key={tab.id}
                  onClick={() => setTabActive(tab.id)}
                  className={
                    tabActive === tab.id
                      ? `${styles.tab} ${styles.selected}`
                      : styles.tab
                  }>
                  <div className={styles.tab_title}>
                    {tab.icon}
                    {t(tab.title)}
                  </div>
                </li>
              )
            })}
          </ul>
          <div className={styles.content}>
            {tabActive === 0 && (
              <SelectorContainerMoto
                showMarcas={false}
                onSumbitMedidas={onSumbitMedidas}
                origin={route(routeIndex)}>
                {({ ...props }) => <SelectorMotoDesktop {...props} />}
              </SelectorContainerMoto>
            )}
            {tabActive === 1 && (
              <SelectorModeloMotoDesktop onSubmit={onSumbitModeloMoto} />
            )}
          </div>
          <ModalErrorMedidas />
        </div>
      }
    </div>
  )
}

export default SelectorMotoComponentTabsDesktop
