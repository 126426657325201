import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingMotoCa = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image1_mobile] = getImages(
    data[`image1_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image1_desktop] = getImages(
    data[`image1_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image2_mobile] = getImages(
    data[`image2_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image2_desktop] = getImages(
    data[`image2_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          En <strong>Rodi Motor Services </strong>portem més de 50 anys dedicats
          al món del pneumàtic. El món de la moto tampoc és una excepció, des
          dels inicis la <strong>passió per les motos </strong> ha estat a Rodi
          una constant. Milers de motoristes confien la seva moto als nostres
          professionals que els assessoren i ajuden en la recerca dels
          pneumàtics perfectes.
        </p>
        <p className={styles.main_description}>
          En Rodi podràs comprar i instal·lar una
          <strong> àmplia varietat de pneumàtics per a la teva moto</strong>.
          Disposem d'una gran selecció de pneumàtics per a moto amb les millors
          marques del mercat:{' '}
          <strong>
            Michelin, Pirelli, Metzeler, Dunlop, Continental, Bridgestone...
          </strong>
        </p>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Quins tipus de pneumàtics existeixen per a moto? </h2>
          <p>
            Depenent de l'ús i del tipus de moto un pneumàtic o un altre
            s'adequarà millor a les teves necessitats. En el món de les dues
            rodes hi ha diferents segments o tipus de motos. Segons en quin
            segment es situï la teva motocicleta seran més adequats uns o altres
            pneumàtics. Aquests <strong>segments o tipus de moto </strong>són a
            grans trets:
            <strong>
              {' '}
              Scooter, Trail, Touring, Custom, Naked, Sportiva i Off Road.
            </strong>
          </p>
          <p>
            Partint d'aquests segments, podríem identificar tres classes
            diferents de pneumàtics per a motocicleta en l'actualitat:
          </p>
          <ul>
            <li>
              <strong>Pneumàtics Carretera o Urbà</strong>: És el més comú. El
              munten els models de motos Naked, les motos Scooter i la majoria
              de Motos 125. Fabricats amb gomes dures que funcionen bé a baixa
              temperatura assolible amb facilitat. Són ideals per a trajectes
              urbans i passejos.
              <ResponsiveImages
                imageDesktop={image1_desktop}
                imageMobile={image1_mobile}
              />
            </li>
            <li>
              <strong>Pneumàtic Esportiu</strong>: També anomenats{' '}
              <strong>Sport o Supersport</strong>. Són els pneumàtics que munten
              les motos esportives, s'usen habitualment en circuit. Implementen
              la més alta tecnologia en pneumàtics. Ofereixen la millor
              adherència en condicions de competició. Munten{' '}
              <strong>gomes toves </strong> amb pocs dibuixos. Requereixen
              arribar a una temperatura elevada per oferir la màxim adherència a
              altes velocitats.
            </li>
            <li>
              <strong>Pneumàtic Mixt o Semi-Asfàltic</strong>: Estan fabricats
              principalment <strong>per a ser usat en Motos Trail </strong>que
              puguin sortir ocasionalment fora de l'asfalt. Dins d'aquesta
              categoria hi ha diferents models i classes amb més o menys
              adequació per a ser usat a Off Road. Es caracteritzen per tenir
              rodes del darrere amples amb dibuixos grans dividits en tacs que
              faciliten l'adherència a pista. Fabricats amb gomes dures, tenen
              una temperatura de funcionament relativament baixa i una molt bona
              durada.
            </li>
            <li>
              <strong>Pneumàtic Off Road</strong>: Es caracteritzen per estar
              formats per<strong> grans tacs </strong> amples que permeten al
              pneumàtic obtenir la
              <strong> màxima adherència a superfícies toves </strong>fora
              d'asfalt. S'utilitzen per a pista de terra, trialeres, camins...
              Les munten <strong> motos d'Off Road </strong>per conductors que
              practiquen<strong> Enduro i Motocross</strong>. Aquesta classe de
              pneumàtics son desaconsellats per utilitzar-se a carretera a causa
              de la poca adherència sobre l'asfalt i, en ocasions, falta
              d'homologació per a la circulació.
              <ResponsiveImages
                imageDesktop={image2_desktop}
                imageMobile={image2_mobile}
              />
            </li>
          </ul>
        </section>
        <section className={styles.section}>
          <h2>Quan he de canviar els Pneumàtics a la meva Moto?</h2>
          <p>
            Has observat que el dibuix dels pneumàtics de la teva moto ha deixat
            d'apreciar-se? Aleshores ha arribat el moment de canviar les rodes.
            Els pneumàtics de moto, a més, disposen d'un testimoni indicador de
            desgast. Quan la goma del pneumàtic és consumida fins a arribar
            aquest testimoni significarà que ha arribat el moment de substituir
            els teus pneumàtics. Una altra referència que pots prendre és
            l'establert pel Reglament General de Vehicles on s'indica que el
            dibuix del pneumàtic ha de trobar-se amb un mínim de 1,6 mm de
            profunditat.
          </p>
          <p>
            Els principals fabricants de pneumàtics han elaborat les seves
            solucions per a furgonetes. És el cas, per exemple, dels{' '}
            <strong>Michelin Agilis CrossClimate,</strong> els{' '}
            <strong>Pirelli Carrier</strong> o els{' '}
            <strong>Goodyear EfficientGrip.</strong>
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingMotoEs = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image1_mobile] = getImages(
    data[`image1_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image1_desktop] = getImages(
    data[`image1_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image2_mobile] = getImages(
    data[`image2_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image2_desktop] = getImages(
    data[`image2_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          En <strong>{getFullCompany()} </strong>llevamos más de
          <strong> 50 años dedicados al mundo del neumático</strong>. El mundo
          de la moto tampoco es una excepción, desde sus inicios la{' '}
          <strong>pasión por las motos </strong>ha sido en Rodi una constante.
          Miles de motoristas confían su moto a nuestros profesionales quienes
          les asesoran y ayudan en la búsqueda de los neumáticos perfectos.
        </p>
        <p className={styles.main_description}>
          En Rodi podrás comprar e instalar una amplia variedad de{' '}
          <strong>neumáticos para tu moto</strong>. Disponemos de una gran
          <strong> selección de neumáticos para moto</strong> con las mejores
          marcas del mercado:{' '}
          <strong>
            Michelin, Pirelli, Metzeler, Dunlop, Continental, Bridgestone...
          </strong>
        </p>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>¿Qué tipos de neumáticos existen para moto?</h2>
          <p>
            Dependiendo del uso y del tipo de moto un neumático u otro se
            adecuará mejor a tus necesidades. En el mundo de las dos ruedas
            existen diferentes<strong> segmentos o tipos de motos</strong>.
            Según en qué segmento se sitúe tu motocicleta serán más adecuados
            unos u otros neumáticos. Estos segmentos o tipos de moto son a
            grandes rasgos:
            <strong>
              {' '}
              Scooter, Trail, Touring, Custom, Naked, Sportiva y Off Road.
            </strong>
          </p>
          <p>
            Partiendo de estos segmentos, podríamos identificar tres clases
            diferentes de neumáticos para motocicleta en la actualidad:
          </p>
          <ul>
            <li>
              <strong>Neumáticos Carretera o Urbano</strong>: Es el más común.
              Lo montan los modelos de <strong>Motos Naked</strong>, las
              <strong> Motos Scooter </strong>y la mayoría de{' '}
              <strong>Motos 125</strong>. Fabricados con gomas duras que
              funcionan bien a baja temperatura alcanzable con facilidad. Son
              ideales para trayectos urbanos y paseos.
              <ResponsiveImages
                imageDesktop={image1_desktop}
                imageMobile={image1_mobile}
              />
            </li>
            <li>
              <strong>Neumático Deportivo</strong>: También llamados{' '}
              <strong>Sport o Supersport</strong>. Son los neumáticos que montan
              las<strong> motos deportivas</strong>, se usan habitualmente en
              circuito. Implementan la más<strong> alta tecnología </strong>en
              neumáticos. Ofrecen el mejor agarre en condiciones de competición.
              Montan<strong> gomas blandas </strong>con pocos dibujos. Requieren
              alcanzar una temperatura elevada para ofrecer el máximo agarre a
              altas velocidades.
            </li>
            <li>
              <strong>Neumático Mixto o Semi-Asfáltico</strong>: Están
              fabricados principalmente
              <strong> para ser usado en motos Trail </strong>que puedan salir
              ocasionalmente fuera del asfalto. Dentro de esta categoría existen
              diferentes modelos y clases con mayor o menor adecuación para ser
              usado en Off-Road. Se caracterizan por tener
              <strong>
                {' '}
                ruedas traseras anchas con dibujos grandes divididos en tacos
              </strong>{' '}
              que facilitan el agarre en pista. Fabricados con gomas duras,
              tienen una temperatura de funcionamiento relativamente baja y una
              muy buena duración.
            </li>
            <li>
              <strong>Neumático Off Road</strong>: Se caracterizan por estar
              formados por<strong> grandes tacos </strong>anchos que permiten al
              neumático obtener el
              <strong> máximo agarre en superficies blandas </strong>fuera de
              asfalto. Se utilizan para pista de tierra, trialeras, caminos… Las
              montan <strong> motos de Off Road </strong>por conductores que
              practican<strong> Enduro y Motocross</strong>. Esta clase de
              neumáticos para motos Off Road se desaconseja usar en carretera
              debido a su poco agarre sobre el asfalto y, en ocasiones, falta de
              homologación para la circulación.
              <ResponsiveImages
                imageDesktop={image2_desktop}
                imageMobile={image2_mobile}
              />
            </li>
          </ul>
        </section>
        <section className={styles.section}>
          <h2>¿Cuándo debo cambiar los Neumáticos a mi Moto?</h2>
          <p>
            ¿Has observado que el dibujo de los neumáticos de tu moto ha dejado
            de apreciarse? Entonces ha llegado el momento de cambiar las ruedas.
            Los neumáticos de moto, además, disponen de un{' '}
            <strong> testigo indicador de desgaste</strong>. Cuando la goma del
            neumático es consumida hasta alcanzar este testigo significará que
            ha llegado el momento de sustituir tus neumáticos. Otra referencia
            que puedes tomar es el establecido por el Reglamento General de
            Vehículos donde se indica que el dibujo del neumático debe
            encontrarse con un mínimo de<strong> 1,6 mm de profundidad</strong>.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingMoto = ({ ...props }: Props) =>
  locale === 'es' ? <LandingMotoEs {...props} /> : <LandingMotoCa {...props} />

export default LandingMoto
