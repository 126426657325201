import React from 'react'
import styles from './SelectorMotoComponent.module.scss'
import Size from '../../../../src/common/utils/media-queries-setup'
import IndexMotoMobile from '../FunnelSelectorMoto/Mobile/IndexMotoMobile'
import { navigate } from 'gatsby'
import Step, { StepDetails } from './SelectorMotoSteps'
import SelectorMotoComponentTabsDesktop from './Desktop/SelectorMotoComponentTabsDesktop'

const SelectorMotoComponent = ({ route: routeIndex }) => {
  const onSubmit = () => {
    navigate(StepDetails[Step.SELECT_PREFERENCIAS].routes.desktop)
  }

  return (
    <div>
      {
        <Size.Matcher
          mobile={
            <div className={styles.tabs_home_wrapper}>
              <div className={styles.content_mobile}>
                <IndexMotoMobile />
              </div>
            </div>
          }
          desktop={
            <SelectorMotoComponentTabsDesktop
              routeIndex={routeIndex}
              onSumbitMedidas={onSubmit}
              onSumbitModeloMoto={onSubmit}
            />
          }
        />
      }
    </div>
  )
}

export default SelectorMotoComponent
