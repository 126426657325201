import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { setOrigen } from '../../../context/actions/selectorActions'
import Step, {
  findNext,
  InSkipInfo,
} from '../../FunnelSelectorMoto/SelectorMotoSteps'
import AppContext, {
  IAppState,
} from '../../../../../src/common/context/context'
import { selectSkipInfoMoto } from '../../../context/selectors/selectorMotoSelectors'
import Next from '../../../../../src/common/assets/images/Talleres/next.svg'
import Button from '../../../../../src/common/components/buttons/Button'
import { t } from '../../../i18n'
import { buscarPorMedidas } from '../../../context/actions/selectorMotoActions'
import SVGMotoSelectorMedidas from '../Common/SVGMotoSelectorMedidas/SVGMotoSelectorMedidas'
import styles from '../SelectorMotoComponent.module.scss'

interface Props {
  skipInfo: InSkipInfo
}

const IndexMotoMobile = ({ skipInfo }: Props) => {
  const { dispatch, selectorMotoState } = useContext(AppContext)
  const onSubmit = async () => {
    dispatch(setOrigen(document.location.pathname))
    await buscarPorMedidas(selectorMotoState, dispatch)
    const next = findNext(Step.INITIAL, 'mobile', skipInfo, selectorMotoState)
    navigate(next.routes.mobile)
  }
  return (
    <>
      <SVGMotoSelectorMedidas showDefaults={true} />
      <div className={styles.next_button}>
        <Button buttonClass="next_button" onClick={onSubmit}>
          {t('selector-desktop.buscar')}
          <Next />
        </Button>
      </div>
    </>
  )
}

export default connect((state: IAppState) => ({
  skipInfo: selectSkipInfoMoto(state),
}))(IndexMotoMobile)
